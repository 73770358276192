var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var ffa,gfa,hfa,ifa,jfa,kfa,lfa,WG,mfa,nfa,ofa,pfa,qfa,rfa,sfa,tfa,YG,ufa;$CLJS.RG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);ffa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.SG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);gfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
hfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.TG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.UG=new $CLJS.M(null,"column-name","column-name",551523580);ifa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.VG=new $CLJS.M(null,"display-info","display-info",-816930907);jfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
kfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);lfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);WG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);mfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);nfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ofa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
pfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);qfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);rfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);sfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.XG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);tfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
YG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.ZG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);ufa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.HF($CLJS.Wx,$CLJS.H([$CLJS.pt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null)],null)]));$CLJS.HF($CLJS.rG,$CLJS.H([$CLJS.pt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)],null)],null)]));
$CLJS.FF($CLJS.kG,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.FF($CLJS.WF,$CLJS.H([$CLJS.pt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null)]));$CLJS.FF($CLJS.zG,$CLJS.H([$CLJS.pt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));
$CLJS.FF($CLJS.kk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null)]));$CLJS.yF($CLJS.kk,$CLJS.kF);$CLJS.FF($CLJS.EG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.yF($CLJS.EG,$CLJS.kF);$CLJS.FF($CLJS.$n,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null)]));$CLJS.yF($CLJS.$n,$CLJS.kF);
$CLJS.X(WG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.wt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.wt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.FF($CLJS.bG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WG],null)]));$CLJS.yF($CLJS.bG,$CLJS.kF);
$CLJS.FF($CLJS.xG,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));$CLJS.FF($CLJS.FG,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.FF($CLJS.DG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));
$CLJS.FF($CLJS.MG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.yF($CLJS.DG,$CLJS.kF);$CLJS.yF($CLJS.MG,$CLJS.kF);$CLJS.FF($CLJS.KG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));$CLJS.yF($CLJS.KG,$CLJS.kF);
$CLJS.FF($CLJS.XF,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.X(YG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ys,tfa,rfa,kfa,gfa,ffa,jfa,lfa,mfa,nfa,qfa,ofa,ufa,sfa,hfa,pfa,$CLJS.El],null));$CLJS.X($CLJS.SG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.$n,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YG],null)],null));
$CLJS.$G=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.jG,$CLJS.Wx,$CLJS.TG,!1,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Count of rows"),$CLJS.UG,$CLJS.SE("Count"),$CLJS.Qt,$CLJS.SE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.DG,$CLJS.RG,$CLJS.gk,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Sum of ..."),$CLJS.UG,$CLJS.SE("Sum"),$CLJS.Qt,$CLJS.SE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.kG,$CLJS.RG,$CLJS.gk,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Average of ..."),$CLJS.UG,$CLJS.SE("Average"),$CLJS.Qt,$CLJS.SE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.EG,$CLJS.RG,$CLJS.gk,$CLJS.TG,!0,$CLJS.XG,$CLJS.mG,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Median of ..."),$CLJS.UG,$CLJS.SE("Median"),$CLJS.Qt,$CLJS.SE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.WF,$CLJS.RG,$CLJS.li,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Number of distinct values of ..."),$CLJS.UG,$CLJS.SE("Distinct values"),$CLJS.Qt,$CLJS.SE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.MG,$CLJS.RG,$CLJS.gk,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Cumulative sum of ..."),
$CLJS.UG,$CLJS.SE("Sum"),$CLJS.Qt,$CLJS.SE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.jG,$CLJS.rG,$CLJS.TG,!1,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Cumulative count of rows"),$CLJS.UG,$CLJS.SE("Count"),$CLJS.Qt,$CLJS.SE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.FG,$CLJS.RG,$CLJS.gk,$CLJS.TG,
!0,$CLJS.XG,$CLJS.AG,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Standard deviation of ..."),$CLJS.UG,$CLJS.SE("SD"),$CLJS.Qt,$CLJS.SE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.$n,$CLJS.RG,$CLJS.Zi,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Minimum of ..."),$CLJS.UG,$CLJS.SE("Min"),$CLJS.Qt,$CLJS.SE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.jG,$CLJS.kk,$CLJS.RG,$CLJS.Zi,$CLJS.TG,!0,$CLJS.XG,$CLJS.LF,$CLJS.VG,function(){return new $CLJS.h(null,3,[$CLJS.uE,$CLJS.SE("Maximum of ..."),$CLJS.UG,$CLJS.SE("Max"),$CLJS.Qt,$CLJS.SE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ifa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.ZG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vs],null),$CLJS.hf.h($CLJS.jG),$CLJS.$G)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Yi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TG,$CLJS.Ns],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.XG,$CLJS.Yi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VG,$CLJS.md],null)],null));