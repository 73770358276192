var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var EH,FH,HH,IH,Jfa,Kfa,Lfa,JH,GH;$CLJS.CH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.DH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
EH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.xC(b,$CLJS.YC)?$CLJS.nH:$CLJS.xC(b,$CLJS.XD)?$CLJS.iH:$CLJS.xC(b,$CLJS.xD)?$CLJS.kH:null;return $CLJS.n(b)?$CLJS.xF(b,a):!0};
FH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.CH(function(d){return $CLJS.xC($CLJS.VE(d),$CLJS.iD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Ok.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.VE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(EH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
HH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.yt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ej);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(FH(b))].join("")}],null),$CLJS.Te(FH)],null)],null)};
IH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ks,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)],null)],null)};
Jfa=function(a){return $CLJS.Qd($CLJS.AF,$CLJS.hf.g(function(b){var c=$CLJS.VE(b),d=$CLJS.xC(c,$CLJS.gF);b=d?$CLJS.xF($CLJS.uG,b):d;return $CLJS.n(b)?$CLJS.Qj:c},a))};Kfa=function(a){a=$CLJS.DH(function(b){return!$CLJS.xC(b,$CLJS.iD)},$CLJS.hf.g($CLJS.VE,a));return $CLJS.xC(a,$CLJS.gF)?$CLJS.Xj:a};
Lfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.xC($CLJS.VE(c),$CLJS.iD)},b))?Kfa(b):$CLJS.E.g(a,$CLJS.pt)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.xC($CLJS.VE(c),$CLJS.YC)},b)||$CLJS.Qe(function(c){return $CLJS.xC($CLJS.VE(c),$CLJS.xD)},b))?$CLJS.iD:Jfa(b)};JH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.KH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);GH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,GH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.wt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.VE(a);return $CLJS.Qe(function(d){return EH(d,c)},b)}],null)],null));
$CLJS.X(JH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ks,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)],null));
var Mfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.wt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.pt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)],
null);$CLJS.QG.g($CLJS.cs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,HH($CLJS.cs),IH($CLJS.cs)],null));$CLJS.QG.g($CLJS.pt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,HH($CLJS.pt),Mfa,IH($CLJS.pt)],null));$CLJS.HF($CLJS.ds,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,JH],null)]));$CLJS.HF($CLJS.LG,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,JH],null)]));
for(var LH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cs,$CLJS.pt,$CLJS.ds],null)),MH=null,NH=0,OH=0;;)if(OH<NH){var Nfa=MH.X(null,OH);$CLJS.yF(Nfa,$CLJS.KH);OH+=1}else{var PH=$CLJS.y(LH);if(PH){var QH=PH;if($CLJS.Ad(QH)){var RH=$CLJS.lc(QH),Ofa=$CLJS.mc(QH),Pfa=RH,Qfa=$CLJS.D(RH);LH=Ofa;MH=Pfa;NH=Qfa}else{var Rfa=$CLJS.A(QH);$CLJS.yF(Rfa,$CLJS.KH);LH=$CLJS.B(QH);MH=null;NH=0}OH=0}else break}
$CLJS.UE.m(null,$CLJS.KH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Lfa(a,b)});$CLJS.FF($CLJS.RF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));$CLJS.yF($CLJS.RF,$CLJS.kF);
for(var SH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,$CLJS.IF,$CLJS.dG],null)),TH=null,UH=0,VH=0;;)if(VH<UH){var Sfa=TH.X(null,VH);$CLJS.FF(Sfa,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));VH+=1}else{var WH=$CLJS.y(SH);if(WH){var XH=WH;if($CLJS.Ad(XH)){var YH=$CLJS.lc(XH),Tfa=$CLJS.mc(XH),Ufa=YH,Vfa=$CLJS.D(YH);SH=Tfa;TH=Ufa;UH=Vfa}else{var Wfa=$CLJS.A(XH);$CLJS.FF(Wfa,$CLJS.H([$CLJS.pt,$CLJS.UD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));SH=$CLJS.B(XH);TH=null;UH=0}VH=0}else break}
for(var ZH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,$CLJS.oG,$CLJS.vG],null)),$H=null,aI=0,bI=0;;)if(bI<aI){var Xfa=$H.X(null,bI);$CLJS.FF(Xfa,$CLJS.H([$CLJS.pt,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));bI+=1}else{var cI=$CLJS.y(ZH);if(cI){var dI=cI;if($CLJS.Ad(dI)){var eI=$CLJS.lc(dI),Yfa=$CLJS.mc(dI),Zfa=eI,$fa=$CLJS.D(eI);ZH=Yfa;$H=Zfa;aI=$fa}else{var aga=$CLJS.A(dI);$CLJS.FF(aga,$CLJS.H([$CLJS.pt,$CLJS.xj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));ZH=$CLJS.B(dI);$H=null;aI=0}bI=0}else break}$CLJS.FF($CLJS.OF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)],null)]));
$CLJS.UE.m(null,$CLJS.OF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.xC($CLJS.VE(b),$CLJS.xj)&&$CLJS.xC($CLJS.VE(a),$CLJS.xj)?$CLJS.xj:$CLJS.UD});