var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var tF,CF,zF,efa;tF=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=tF[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=tF._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("Cached.-cache",a);}return a};
$CLJS.uF=function(a,b,c){if(null!=a&&$CLJS.Bc===a.Wa){var d=tF(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Oh.v(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.wF=function(a,b,c){a=$CLJS.vF.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.xF=function(a,b){return $CLJS.wF(a,b,null)};$CLJS.yF=function(a,b){$CLJS.Oh.v($CLJS.lC,$CLJS.iB,a,b)};$CLJS.AF=function(a,b){a=zF(a,b);return $CLJS.n(a)?a:$CLJS.Bj};
CF=function(){return $CLJS.eg.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.Oi,$CLJS.A,$CLJS.yt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return $CLJS.zd(a)?["Invalid ",$CLJS.Mh.l($CLJS.H([$CLJS.A(a)]))," clause: ",$CLJS.Mh.l($CLJS.H([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ap,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.wt,"not a known MBQL clause"],null),$CLJS.Ue(!1)],null)],null)],null),$CLJS.hf.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zh.g("mbql.clause",$CLJS.gh(a))],null)],null)}),$CLJS.q(BF))};$CLJS.FF=function(a,b){$CLJS.Se.v(DF,EF,a,b)};$CLJS.HF=function(a,b){$CLJS.Se.v(DF,GF,a,b)};$CLJS.Bp.prototype.ia=$CLJS.va(26,function(){return this.cache});$CLJS.Dp.prototype.ia=$CLJS.va(25,function(){return this.cache});$CLJS.Fp.prototype.ia=$CLJS.va(24,function(){return this.cache});$CLJS.Hp.prototype.ia=$CLJS.va(23,function(){return this.cache});
$CLJS.Jp.prototype.ia=$CLJS.va(22,function(){return this.cache});$CLJS.Lp.prototype.ia=$CLJS.va(21,function(){return this.cache});$CLJS.Op.prototype.ia=$CLJS.va(20,function(){return this.cache});$CLJS.Qp.prototype.ia=$CLJS.va(19,function(){return this.cache});$CLJS.Sp.prototype.ia=$CLJS.va(18,function(){return this.cache});$CLJS.Up.prototype.ia=$CLJS.va(17,function(){return this.cache});$CLJS.Wp.prototype.ia=$CLJS.va(16,function(){return this.cache});$CLJS.Yp.prototype.ia=$CLJS.va(15,function(){return this.cache});
$CLJS.$p.prototype.ia=$CLJS.va(14,function(){return this.cache});$CLJS.bq.prototype.ia=$CLJS.va(13,function(){return this.cache});$CLJS.dq.prototype.ia=$CLJS.va(12,function(){return this.cache});$CLJS.fq.prototype.ia=$CLJS.va(11,function(){return this.cache});$CLJS.iq.prototype.ia=$CLJS.va(10,function(){return this.cache});$CLJS.nq.prototype.ia=$CLJS.va(9,function(){return this.cache});$CLJS.pq.prototype.ia=$CLJS.va(8,function(){return this.cache});$CLJS.sq.prototype.ia=$CLJS.va(7,function(){return this.cache});
$CLJS.Cq.prototype.ia=$CLJS.va(6,function(){return this.cache});$CLJS.dE.prototype.ia=$CLJS.va(5,function(){return this.cache});$CLJS.vF=function vF(a){switch(arguments.length){case 1:return vF.h(arguments[0]);case 2:return vF.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.vF.h=function(a){return $CLJS.vF.g(a,null)};$CLJS.vF.g=function(a,b){return $CLJS.uF($CLJS.zo.g(a,b),$CLJS.yk,$CLJS.xn)};$CLJS.vF.A=2;
zF=function zF(a,b){return $CLJS.E.g(a,$CLJS.Bj)?null:$CLJS.E.g(b,$CLJS.Bj)?null:$CLJS.xC(a,b)?b:$CLJS.xC(b,a)?a:$CLJS.Re(function(d){return $CLJS.Re(function(e){return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:zF.g?zF.g(d,e):zF.call(null,d,e)},$CLJS.ee(b,$CLJS.Yh(b)))},$CLJS.ee(a,$CLJS.Yh(a)))};$CLJS.IF=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.JF=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.KF=new $CLJS.M(null,"dimension","dimension",543254198);$CLJS.LF=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.MF=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.NF=new $CLJS.M(null,"query","query",-1288509510);$CLJS.OF=new $CLJS.M(null,"power","power",-937852079);$CLJS.PF=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.QF=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.RF=new $CLJS.M(null,"abs","abs",-246026477);
$CLJS.SF=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.TF=new $CLJS.M(null,"between","between",1131099276);$CLJS.UF=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.VF=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.WF=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.XF=new $CLJS.M(null,"var","var",-769682797);$CLJS.YF=new $CLJS.M(null,"next","next",-117701485);$CLJS.ZF=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.$F=new $CLJS.M(null,"case","case",1143702196);
$CLJS.aG=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.bG=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.cG=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.dG=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.eG=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.fG=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.gG=new $CLJS.M(null,"desc","desc",2093485764);$CLJS.hG=new $CLJS.M(null,"starts-with","starts-with",366503009);
$CLJS.iG=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.jG=new $CLJS.M(null,"short","short",1928760516);$CLJS.kG=new $CLJS.M(null,"avg","avg",197406200);$CLJS.lG=new $CLJS.M(null,"metric","metric",408798077);$CLJS.mG=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.nG=new $CLJS.M(null,"asc","asc",356854569);$CLJS.oG=new $CLJS.M(null,"floor","floor",1882041021);$CLJS.pG=new $CLJS.M(null,"contains","contains",676899812);
$CLJS.qG=new $CLJS.M(null,"field","field",-1302436500);$CLJS.rG=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.sG=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.tG=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.uG=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.vG=new $CLJS.M(null,"round","round",2009433328);$CLJS.wG=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);
$CLJS.xG=new $CLJS.M(null,"share","share",-589433933);$CLJS.yG=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.zG=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.AG=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.BG=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.CG=new $CLJS.M(null,"time-interval","time-interval",704622015);$CLJS.DG=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.EG=new $CLJS.M(null,"median","median",569566131);$CLJS.$J=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);efa=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.FG=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.GG=new $CLJS.M(null,"trim","trim",774319767);$CLJS.HG=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.IG=new $CLJS.M(null,"ltrim","ltrim",1654269283);
$CLJS.JG=new $CLJS.M(null,"category","category",-593092832);$CLJS.KG=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.LG=new $CLJS.M(null,"/","/",1282502798);$CLJS.MG=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.NG=new $CLJS.M(null,"log","log",-1595516004);$CLJS.OG=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.PG=new $CLJS.M(null,"rtrim","rtrim",-661336449);var BF=$CLJS.Xe($CLJS.bh),GF;$CLJS.X($CLJS.WE,CF());$CLJS.mC(BF,efa,function(){return $CLJS.X($CLJS.WE,CF())});
$CLJS.QG=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.UE.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.zh.g("mbql.clause",$CLJS.gh(d));$CLJS.X(f,e);$CLJS.Hd($CLJS.q(BF),d)||$CLJS.Oh.j(BF,$CLJS.be,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
GF=function GF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return GF.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
GF.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,$CLJS.eg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ps,new $CLJS.h(null,1,[$CLJS.wt,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null)],null)],null),b)],null)};GF.A=1;
GF.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var EF=function EF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return EF.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
EF.l=function(a,b){return $CLJS.eg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.h(null,1,[$CLJS.wt,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),b)};EF.A=1;EF.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var DF=function DF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return DF.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};DF.l=function(a,b,c){c=$CLJS.E.g($CLJS.A(c),$CLJS.pt)?$CLJS.ee($CLJS.hd(c),$CLJS.bf(2,c)):$CLJS.ee(null,c);var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);a=$CLJS.Se.j(a,b,d);return $CLJS.n(c)?$CLJS.QG.v(b,$CLJS.pt,c,a):$CLJS.QG.g(b,a)};DF.A=2;
DF.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};