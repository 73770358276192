var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var GL,IL,KL,NL,SL;$CLJS.CL=function(a){return $CLJS.Wh($CLJS.q($CLJS.lC),a,$CLJS.uG)};$CLJS.DL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.h6=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.FL=new $CLJS.M(null,"object-id","object-id",-754527291);GL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.HL=new $CLJS.M(null,"operators","operators",-2064102509);
IL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.JL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);KL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.LL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.ML=new $CLJS.M(null,"dimensions","dimensions",-254818097);
NL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.OL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.PL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.QL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.RL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
SL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.TL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(IL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.EE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.mH],null)],null)],null));$CLJS.X(SL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,IL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.JE],null)],null)],null));$CLJS.X(KL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.qG],null),SL,$CLJS.zE],null));
$CLJS.X(NL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.qG],null),IL,$CLJS.IE],null));
$CLJS.QG.g($CLJS.qG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.qG],null),IL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.IE,$CLJS.zE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.Oi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.jC(a)):null},$CLJS.wt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.nB,NL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,KL],null)],null)],null));$CLJS.yF($CLJS.qG,$CLJS.uG);$CLJS.UE.m(null,$CLJS.qG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Fi,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.gF});$CLJS.FF($CLJS.tB,$CLJS.H([$CLJS.zE]));$CLJS.UE.m(null,$CLJS.tB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Fi,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.gF});
$CLJS.yF($CLJS.tB,$CLJS.uG);$CLJS.X(GL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.EE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uE,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.zE],null)],null)],null));$CLJS.QG.g($CLJS.fG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,$CLJS.fG],null),GL,$CLJS.ak],null));
$CLJS.UE.m(null,$CLJS.fG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Fi,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.gF});$CLJS.yF($CLJS.fG,$CLJS.uG);$CLJS.FF($CLJS.PF,$CLJS.H([$CLJS.pt,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rE],null)],null)]));$CLJS.yF($CLJS.PF,$CLJS.uG);
$CLJS.FF($CLJS.lG,$CLJS.H([$CLJS.pt,$CLJS.gF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null)],null)]));$CLJS.yF($CLJS.lG,$CLJS.uG);
$CLJS.X($CLJS.uG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.WE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.yt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Ht(", ",$CLJS.bH($CLJS.Jd,$CLJS.ll.g($CLJS.q($CLJS.lC),$CLJS.uG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.CL(a)}],null)],null));